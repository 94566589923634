import React, { useEffect, useState } from 'react';
import './Home.css';

const Home = () => {
    const [images, setImages] = useState([]);
    const [showNsfw, setShowNsfw] = useState(false);
    const [hideNsfw, setHideNsfw] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('Hepsini Göster');

    useEffect(() => {
        fetch('https://vorp-anim-menu.vercel.app/images', {
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                setImages(data);
            })
            .catch(error => console.error('Error fetching images:', error));
    }, []);

    const handleImageClick = (image) => {
        const modal = document.getElementById('modal');
        const modalImage = document.getElementById('modalImage');
        const modalTitle = document.getElementById('modalTitle');
        const captionText = document.getElementById('caption');
        modal.style.display = "block";
        modalImage.src = image.url;
        modalTitle.innerHTML = image.title;
        captionText.innerHTML = image.description;
    };

    const handleCloseModal = () => {
        const modal = document.getElementById('modal');
        modal.style.display = "none";
    };

    const handleToggleNsfw = () => {
        setShowNsfw(!showNsfw);
    };

    const handleToggleHideNsfw = () => {
        setHideNsfw(!hideNsfw);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const filteredImages = images
        .filter(image => !hideNsfw || !image.nsfw)
        .filter(image => image.title.toLowerCase().includes(searchTerm.toLowerCase()))
        .filter(image => {
            if (filter === 'Nearby') {
                return image.title.toLowerCase().includes('nearby');
            } else if (filter === 'Custom') {
                return image.title.toLowerCase().includes('custom');
            } else if (filter === 'We') {
                return image.title.toLowerCase().startsWith('/we') && !image.title.toLowerCase().includes('custom');
            }
            return true;
        })
        .sort((a, b) => a.title.localeCompare(b.title));

    if (!images) {
        return <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>Loading...</div>;
    }

    return (
        <div className="home">
            <div className="upperArea">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search by title..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                <select className="filter-dropdown" value={filter} onChange={handleFilterChange}>
                    <option value="Hepsini Göster">Hepsini Göster</option>
                    <option value="Nearby">Nearby</option>
                    <option value="Custom">Custom</option>
                    <option value="We">We</option>
                </select>
            </div>

            <div className="upperArea">
                <button className="toggle-hide-nsfw-button" onClick={handleToggleHideNsfw}>
                    {hideNsfw ? 'NSFW İçerikleri Listele' : 'NSFW İçerikleri Listeden Gizle'}
                </button>
                {!hideNsfw && (
                    <button className="toggle-nsfw-button" onClick={handleToggleNsfw}>
                        {showNsfw ? 'NSFW Görselleri Gizle' : 'NSFW Görselleri Göster'}
                    </button>
                )}
            </div>
            <div className="images-grid">
                {filteredImages.map(image => (
                    <div key={image._id} className="image-container">
                        {image.nsfw && !showNsfw ? (
                            <div className="nsfw-overlay">
                                <span>NSFW!</span>
                            </div>
                        ) : null}
                        <img
                            src={image.url}
                            alt={image.title}
                            className={`thumbnail ${image.nsfw && !showNsfw ? 'blurred' : ''}`}
                            onClick={() => handleImageClick(image)}
                        />
                        <p>{image.title}</p>
                    </div>
                ))}
            </div>
            <div id="modal" className="modal" onClick={handleCloseModal}>
                <span className="close" onClick={handleCloseModal}>&times;</span>
                <img className="modal-content" id="modalImage" />
                <p className='AnimName'>Anim: <span id="modalTitle"></span></p>
                <p id="caption"></p>
            </div>
        </div>
    );
}

export default Home;
