import React, { useState } from 'react';
import './AddImagePage.css';
import { Navigate } from 'react-router-dom';

const AddImagePage = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [nsfw, setNsfw] = useState(false);
    const [url, setUrl] = useState('');
    const [file, setFile] = useState(null);
    const [useLink, setUseLink] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (useLink) {
            fetch('https://vorp-anim-menu.vercel.app/images', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ title, description, nsfw, url }),
            })
            .then(response => response.json())
            .then(data => {
                console.log('Image added:', data);
                setRedirect(true);
                setTitle('');
                setDescription('');
                setNsfw(false);
                setUrl('');
            })
            .catch(error => console.error('Error adding image:', error));
        } else {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('nsfw', nsfw);
            formData.append('file', file);

            fetch('https://vorp-anim-menu.vercel.app/uploadimages', {
                method: 'POST',
                credentials: 'include',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                console.log('Image added:', data);
                setRedirect(true);
                setTitle('');
                setDescription('');
                setNsfw(false);
                setFile(null);
            })
            .catch(error => console.error('Error adding image:', error));
        }
    };

    if (redirect) {
        return <Navigate to="/" />;
    }

    return (
        <div className="add-image-container">
            <h1>Add Image</h1>
            <div className="button-group">
                <button type="button" onClick={() => setUseLink(false)} className={!useLink ? 'active' : ''}>
                    Dosya Ekle
                </button>
                <button type="button" onClick={() => setUseLink(true)} className={useLink ? 'active' : ''}>
                    Link Ekle
                </button>
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Title:</label>
                    <input type="text" placeholder='Animasyon Kodu örnek: /e sig2' value={title} onChange={(e) => setTitle(e.target.value)} required />
                </div>
                <div>
                    <label>Description:</label>
                    <textarea value={description} placeholder='İstemiyorsan bir tane boşluk bırakabilirsin.' onChange={(e) => setDescription(e.target.value)} required></textarea>
                </div>
                <div>
                    <label>NSFW:</label>
                    <input type="checkbox" checked={nsfw} onChange={(e) => setNsfw(e.target.checked)} />
                </div>
                {useLink ? (
                    <div>
                        <label>Image URL:</label>
                        <input type="url" placeholder='örnek: https://i.hizliresim.com/aqtwmiu.png (Aspect Ratio 1.2x1)' value={url} onChange={(e) => setUrl(e.target.value)} required />
                    </div>
                ) : (
                    <div>
                        <label>Image File:</label>
                        <input type="file" onChange={(e) => setFile(e.target.files[0])} required />
                    </div>
                )}
                <button type="submit">Add Image</button>
            </form>
        </div>
    );
};

export default AddImagePage;
